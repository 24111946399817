import { commonConstants } from 'constants/index';

const { API_RESPONSE_TIME_BUCKETS } = commonConstants;

/**
 * Generates a suitable screenName based on route path,
 * following this format:
 * - / --> .
 * - /orders/marketplace --> .orders.marketplace
 * - /orders/sellers/:sellerId/:id --> .orders.sellers.&sellerId.&id
 *
 * @param path route path
 * @returns screenName to use in tracker-js
 */
const computeTrackingScreenName = (path: string) => {
  const PATTERN_REGEX = /[/:]/g;
  const replacedPattern: Record<string, string> = {
    '/': '.',
    ':': '&',
  };
  return path.replace(PATTERN_REGEX, value => replacedPattern[value]);
};

const getBucket = (responseTime: number) => {
  let low = 0;
  let high = API_RESPONSE_TIME_BUCKETS.length - 1;

  while (low < high) {
    const mid = Math.floor((low + high) / 2);

    if (responseTime <= API_RESPONSE_TIME_BUCKETS[mid].value) {
      high = mid;
    } else {
      low = mid + 1;
    }
  }

  return API_RESPONSE_TIME_BUCKETS[low].label;
};

const nonBlockTrackResponseTime = (contentName: string, duration: number) => {
  setTimeout(() => {
    track('customEvent', contentName, {
      action: 'apiCallTime',
      category: 'staff-web-app',
      label: getBucket(duration),
      value: duration,
    });
  }, 0);
};

const nonBlockTrackResponseTimeWrapper = async <T>(
  apiCall: Promise<T>,
  contentName: string
): Promise<T> => {
  const startTime = Math.round(performance.now());
  let response = null;

  try {
    response = await apiCall;
  } catch (error) {
    throw error;
  }

  const endTime = Math.round(performance.now());
  const duration = endTime - startTime;

  nonBlockTrackResponseTime(contentName, duration);
  return response;
};

export default {
  computeTrackingScreenName,
  getBucket,
  nonBlockTrackResponseTime,
  nonBlockTrackResponseTimeWrapper,
};
