import { commonConstants, flagConstants } from 'constants/index';
import { trackingHelpers } from 'helpers';
import userHelpers from 'helpers/user';
import {
  IFlagsData,
  IGetPlatformConfigParams,
  IPlatform,
  ISeller,
  Website,
} from 'interfaces';
import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { sellerServices, staffServices } from 'services';
import { IUserDetailsInfo } from 'teko-oauth2';

const {
  getCurrentPlatform,
  setCurrentPlatform,
  getAuthorizedSellers,
  getCurrentSeller,
  setCurrentSeller,
} = userHelpers;

const { nonBlockTrackResponseTimeWrapper } = trackingHelpers;

const { DEFAULT_NUMBER_DISPLAY_FORMAT } = commonConstants;

const usePlatformInfo = (currentUser: IUserDetailsInfo | undefined) => {
  const [platforms, setPlatforms] = useState<IPlatform[]>([]);
  const [fetchingPlatforms, setFetchingPlatforms] = useState(true);
  const currentPlatform = getCurrentPlatform();
  const [platformInfo, setPlatformInfo] = useState<IPlatform>();

  const currentMetaPlatformId = get(currentUser, ['meta_data', 'platformId']);

  const getAuthorizedPlatforms = () => {
    let authorizedPlatforms: IPlatform[] = [];
    if (currentUser) {
      const configAllPlatforms = currentMetaPlatformId === 0;
      // Get authorized platforms from IAM user config
      authorizedPlatforms = configAllPlatforms
        ? [...platforms]
        : platforms.filter(platform => platform.id === currentMetaPlatformId);
    }
    return authorizedPlatforms;
  };

  const authorizedPlatforms = getAuthorizedPlatforms();

  useEffect(() => {
    const authorizedPlatformsCount = authorizedPlatforms.length;
    if (!!authorizedPlatformsCount) {
      const newPlatformInfo =
        authorizedPlatformsCount > 1
          ? authorizedPlatforms.find(
              platform => platform.id === parseInt(currentPlatform)
            )
          : authorizedPlatforms[0];
      if (newPlatformInfo) {
        if (authorizedPlatformsCount === 1)
          setCurrentPlatform(newPlatformInfo.id);
        setPlatformInfo(newPlatformInfo);
      }
    }
  }, [currentPlatform, authorizedPlatforms]);

  useEffect(() => {
    if (currentUser) {
      // If user has config platformId on IAM, call API to get the authorized platforms (this case user is Seller that sell on Platform)
      if (currentMetaPlatformId === 0 || currentMetaPlatformId) {
        const getAllPlatforms = async () => {
          try {
            const allPlatforms = await nonBlockTrackResponseTimeWrapper(
              sellerServices.getAllPlatforms(),
              '/platforms(all)'
            );
            setPlatforms(allPlatforms);
          } finally {
            setFetchingPlatforms(false);
          }
        };

        getAllPlatforms();
      }
      // If user doesn't have config platformId on IAM, bypass (this case user is Online Seller)
      else setFetchingPlatforms(false);
    }
  }, [currentUser, currentMetaPlatformId]);

  return {
    fetchingPlatforms,
    authorizedPlatforms,
    platformInfo,
  };
};

const useSellerData = (
  fetchingPlatforms: boolean,
  platformInfo: IPlatform | undefined
) => {
  const [sellers, setSellers] = useState<ISeller[]>([]);
  const [fetchingSellers, setFetchingSellers] = useState(true);

  useEffect(() => {
    // When fetching platform done, fetch sellers based on platform config
    if (!fetchingPlatforms) {
      const getAllSellers = async () => {
        try {
          const params = {
            // If current user has platform info, filter sellers that sell on current platform
            // Else filter all sellers
            platformIds: platformInfo ? `${platformInfo.id}` : undefined,
          };
          const res = await sellerServices.getAllSellers(params);
          if (res.sellers) setSellers(res.sellers);
        } finally {
          setFetchingSellers(false);
        }
      };

      getAllSellers();
    }
  }, [fetchingPlatforms, platformInfo]);

  return { sellers, fetchingSellers };
};

const useSellerInfo = (
  currentUser: IUserDetailsInfo | undefined,
  fetchingPlatforms: boolean,
  platformInfo: IPlatform | undefined,
  enableFlagToGetPlatformConfig?: boolean
) => {
  // Get list sellers based on platform information
  const { sellers, fetchingSellers } = useSellerData(
    fetchingPlatforms,
    platformInfo
  );
  const currentSeller = getCurrentSeller();
  const [sellerInfo, setSellerInfo] = useState({} as ISeller);

  const [platformConfigBySeller, setPlatformConfigBySeller] = useState<
    IPlatform
  >({
    id: 0,
    name: '',
    numberDisplayFormat: DEFAULT_NUMBER_DISPLAY_FORMAT,
  });

  const authorizedSellers = useMemo(() => {
    return getAuthorizedSellers(currentUser, sellers);
  }, [currentUser, sellers]);

  const getPlatformConfigBySeller = async (
    params: IGetPlatformConfigParams
  ) => {
    try {
      const platformConfig = await staffServices.getPlatformConfig(params);
      if (platformConfig?.numberDisplayFormat?.thousandSeparator) {
        setPlatformConfigBySeller(platformConfig);
      }
    } catch {}
  };

  useEffect(() => {
    if (!!sellerInfo.id && enableFlagToGetPlatformConfig) {
      getPlatformConfigBySeller({ sellerId: sellerInfo.id });
    }
  }, [sellerInfo.id]);

  useEffect(() => {
    const authorizedSellersCount = authorizedSellers.length;
    if (!!authorizedSellersCount) {
      const newSellerInfo =
        authorizedSellersCount > 1
          ? authorizedSellers.find(
              seller => seller.id === parseInt(currentSeller)
            )
          : authorizedSellers[0];
      if (newSellerInfo) {
        if (authorizedSellersCount === 1) setCurrentSeller(newSellerInfo.id);
        setSellerInfo(newSellerInfo);
      }
    }
  }, [currentSeller, authorizedSellers]);

  return {
    sellers,
    fetchingSellers,
    authorizedSellers,
    sellerInfo,
    platformConfigBySeller,
  };
};

const useWebsites = ({
  currentUser,
  featureFlagsData,
}: {
  currentUser?: IUserDetailsInfo;
  featureFlagsData: IFlagsData;
}) => {
  const [websites, setWebsites] = useState<Website[]>([]);
  const [fetchingWebsites, setFetchingWebsites] = useState<boolean>(false);
  const isEcom474Enabled =
    featureFlagsData[flagConstants.FLAG_KEYS.INTEGRATE_TEMPI_WEBSITE_BUILDER]
      ?.enabled;

  useEffect(() => {
    const userPlatformId = currentUser?.meta_data.platformId;
    const isMerchantUser = !!currentUser?.meta_data.merchant_id;

    if (isEcom474Enabled && isMerchantUser && userPlatformId) {
      setFetchingWebsites(true);
      staffServices
        .getWebsitesByPlatformId(userPlatformId)
        .then(value => setWebsites(value))
        .finally(() => {
          setFetchingWebsites(false);
        });
    }
  }, [currentUser, isEcom474Enabled]);

  return {
    websites,
    fetchingWebsites,
  };
};

export default {
  usePlatformInfo,
  useSellerData,
  useSellerInfo,
  useWebsites,
};
